import 'bootstrap';
import 'slick-carousel';
//import noUiSlider from 'nouislider';
import wNumb from 'wnumb';




$(document).ready(function () {

 /* $('body').on('click', '.dropdown__btn', function(event) {
    var parent = $(this).parent();
    var dropdown = parent.find('.dropdown-content');
    var selected = parent.find('.dropdown__selected');
    dropdown.toggleClass('show');
    event.preventDefault();
  });*/


  $('body').on('click', '.box__invoices button', function(event) {
    $(this).parent().toggleClass('active');
    event.preventDefault();
  });

  $('body').on('click', '.dropdown-content a', function(event) {
    var parent = $(this).parent().parent();
    var drop_val = parent.find('.drop_select');
    drop_val.html($(this).html());
    parent.find('.dropdown__selected').addClass('show')
    $(this).parent().toggleClass('show');
    event.preventDefault();
  });

  $('body').on('click', '.btn__remove', function(event) {
    var parent = $(this).parent().parent();
    parent.find('.dropdown__selected').toggleClass('show');
    parent.find('.drop_select').html('');
    event.preventDefault();
    return false;
  });


  $('.doc_slider').slick({
      dots: false,
      infinite: false,
      lazyLoad: 'ondemand',
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.doc_slider_l'),
      nextArrow: $('.doc_slider_r'),
     /* responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '60px',
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '30px',
        },
      },
      ],*/
    });

  $('body').on('click', '.person__item', function(event) {
    event.preventDefault();
    $(this).toggleClass('active');
    var docId = $(this).data('doc');
    $('.fullTimeline').removeClass('one two three one four five');
    countDoctor(docId);
  });


  $('body').on('click', '.btn2__edit', function(event) {
    event.preventDefault();
    let allDrop = document.querySelectorAll('.menu_drop').forEach( e => {
      if(e !== $(this).parent()[0]){
        e.classList.remove("active");
      }
    });
    $(this).parent().toggleClass('active');
    event.stopPropagation();
  });


  $(document).click(function(){
    $('.menu_drop').removeClass('active');
  });


  function countDoctor(docId) {
    var cls = ['', 'one', 'two', 'three', 'four', 'five'];
    var count = $('.person__item.active').length;
    if(count > 2) {
    //  alert('Maksymalna liczba wybranych lekaży w wersji mobilnej to 2, odznacz lekarzy lub przejdź do wersji desktop.');

    }
    $('.fullTimeline').addClass(cls[count]);
    $('.doc'+docId).toggleClass('show');

  }


	//slider
	/*$('.progress').each(function(index, el) {
    var th = $(this);
    var valuenow = 0; //th.attr('aria-valuenow');
    var min = 0;//th.attr('aria-valuemin');
    var max = 10;//th.attr('aria-valuemax');
    noUiSlider.create(el, {
      start: valuenow,
      connect: [true,false],
      tooltips: [wNumb({decimals: 0})],
      step: 1,
      range: {
        'min': 0,
        'max': 10
      },
    });
    el.noUiSlider.on('update', function (values, handle) {
        console.log(Math.round(values[0]));
    });
  });*/

  $('body').on('change', 'input[name=chb1]', function(event) {
    if ($(this).val() == 1) {
      $('.show_progress').removeClass('d-none');
    } else {
      $('.show_progress').addClass('d-none');
    }
    event.preventDefault();
  });

});
const burgerMenu = document.getElementById("burger");
const navbarMenu = document.getElementById("menu");
const navbrandMenu = document.querySelector(".navbrand");

// Responsive Navbar Toggle
if(burgerMenu !== null){
burgerMenu.addEventListener("click", function () {
  navbarMenu.classList.toggle("active");
  burgerMenu.classList.toggle("active");
  navbrandMenu.classList.toggle("active");

});
}

const range = document.getElementById('range');

if(range !== null){



range.addEventListener('input', (e) => {
	// Get the label (which is the nextElementSibling)
	const label = e.target.nextElementSibling;
	// Get value of the input
	const value = +e.target.value;
	// Get the width value of the input
	const range_width = getComputedStyle(e.target).getPropertyValue('width');
	// Get the width value of the label
	const label_width = getComputedStyle(label).getPropertyValue('width');
	// Remove 'px' and conver to number
	const num_width = +range_width.substring(0, range_width.length - 2);
	const num_label_width = +label_width.substring(0, label_width.length - 2);
	// Get min and max values
	const max = +e.target.max;
	const min = +e.target.min;
	// Calculate the left value
	const left = value * (num_width / max) - num_label_width / 2 + scale(value, min, max, 10, -10);

	label.style.left = `${left}px`;
	label.innerHTML = value;

	if(value < 5 && value !== 0){
		range.classList.remove("inp_red");
		range.classList.add("inp_ora");
  } else if (value > 5  && value !== 0){
    range.classList.remove("inp_ora");
    range.classList.add("inp_red");
  } else if (value == 0) {
    range.classList.remove("inp_ora");
    range.classList.remove("inp_red");
  }

});

const scale = (num, in_min, in_max, out_min, out_max) => {
  return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
}

}
